import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import footerLogo from "../assets/logo.svg";
import React from "react";
import "./Footer.css";

function Footer({ setModal, setShow }) {
  return (
    <footer>
      <div className="container">
        <div className="row footer__row">
          <a href="/" className="footer__anchor">
            <figure>
              <img className="footer__logo--img" src={footerLogo} alt="" />
            </figure>
            <span className="footer__logo--popper">
              Top
              <FontAwesomeIcon icon={faArrowUp} />
            </span>
          </a>
          <div className="footer__social--list">
            <a
              href="https://github.com/TorbenWintebach"
              target="_blank"
              rel="noreferrer"
              className="footer__social--link"
            >
              Github
            </a>
            <a
              href="https://www.linkedin.com/in/torben-winterbach-8545a3265/"
              target="_blank"
              rel="noreferrer"
              className="footer__social--link "
            >
              LinkedIn
            </a>
            <a
              onClick={() => {
                setModal(true);
                setShow(false);
              }}
              href="#landingPage"
              className="footer__social--link footer__link--contact "
            >
              Contact
            </a>
            <a
              href="https://drive.google.com/file/d/1wQedTlRmJtdPkTtQ7_HT3MkQn-FinMGh/view?usp=sharing"
              target="_blank"
              rel="noreferrer"
              className="footer__social--link"
            >
              Resume
            </a>
          </div>
          <div className="footer__copyright">
            Copyright © 2023 Torben Winterbach - All Right Reserved
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
