import React from "react";
import { motion } from "framer-motion";
import "./Modal.css";
import ModalLanguage from "./ModalLanguage";
import ReduxImage from "../assets/redux-transparent.png";
import FirebaseImage from "../assets/firebase-transparent.png";

function ModalAbout() {
  return (
    <motion.div
      initial={{
        x: -1000,
        opacity: 0,
      }}
      animate={{
        x: 0,
        opacity: 1,
      }}
      transition={{
        duration: 0.3,
      }}
      className="modal__half modal__about"
    >
      <h3 className="modal__title modal__title--about">
        Here's a bit about me.
      </h3>
      <h4 className="modal__sub-title modal__sub-title--about">
        Frontend Software Engineer.
      </h4>
      <p className="modal__para">
        I'm a 18 year-old Namibian
        <b className="orange"> frontend software engineer</b> with a strong
        passion for developing websites with great
        <b className="orange"> user experiences.</b>
        <br />I currently work on extremely difficult engineering problems and
        learn from a team consisting of some of the most
        <b className="orange"> talented</b> and{" "}
        <b className="orange">experienced</b> software engineers every day.
      </p>
      <div className="modal__languages">
        <ModalLanguage
          image={
            "https://upload.wikimedia.org/wikipedia/commons/thumb/3/38/HTML5_Badge.svg/240px-HTML5_Badge.svg.png"
          }
          name="HTML"
        />
        <ModalLanguage
          image={
            "https://cdn.iconscout.com/icon/free/png-256/css-131-722685.png"
          }
          name="CSS"
        />
        <ModalLanguage
          image={
            "https://cdn.iconscout.com/icon/free/png-256/javascript-1-225993.png"
          }
          name="JavaScript"
        />
        <ModalLanguage
          image={
            "https://cdn.iconscout.com/icon/free/png-256/react-3-1175109.png"
          }
          name="React"
        />
        <ModalLanguage image={ReduxImage} name="Redux" />
        <ModalLanguage
          image={FirebaseImage}
          name="Firebase"
        />
        <ModalLanguage
          image={
            "https://images.g2crowd.com/uploads/product/image/large_detail/large_detail_96102ac6497377cd53da621075fe828e/sanity.png"
          }
          name="Sanity"
        />
        <ModalLanguage
          image={
            "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/Tailwind_CSS_Logo.svg/2048px-Tailwind_CSS_Logo.svg.png"
          }
          name="Tailwind"
        />
      </div>
    </motion.div>
  );
}

export default ModalAbout;
