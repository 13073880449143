import { motion } from "framer-motion";
import "./Modal.css";
import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import CloseIcon from "@mui/icons-material/Close";
import { FaSpinner } from "react-icons/fa";

function ModalContact({ setModal, modal, setShow }) {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    const success = document.getElementById("modal__overlay--SUCCESS");
    const loading = document.getElementById("modal__overlay--LOADING");

    loading.classList.remove("modal__overlay--invisible");
    loading.classList.add("modal__overlay--visible");

    emailjs
      .sendForm(
        "service_j3gikyp",
        "template_vnm22n6",
        form.current,
        "mn4hQP5Q3Nj2LT5Ux"
      )
      .then(
        (result) => {
          loading.classList.remove("modal__overlay--visible");
          success.classList.remove("modal__overlay--invisible");
          success.classList.add("modal__overlay--visible");
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <motion.div
      initial={{
        x: 1000,
        opacity: 0,
      }}
      animate={{
        x: 0,
        opacity: 1,
      }}
      transition={{
        duraction: 0.3,
      }}
      className="modal__half modal__contact"
    >
      <CloseIcon
        className="fas fa-times modal__exit click"
        onClick={() => {
          setModal(false);
          setShow(true);
        }}
      />
      <h3 className="modal__title modal__title--contact">Let's have a chat!</h3>
      <h4 className="modal__sub-title modal__sub-title--contact">
        I'm currently open to new opportunities.
      </h4>
      <form id="contact__form" ref={form} onSubmit={sendEmail}>
        <div className="form__item">
          <label className="form__item--label">
            <b className="orange">*</b> Name
          </label>
          <input
            className="input"
            name="user_name"
            type="text"
            required
          ></input>
        </div>
        <div className="form__item">
          <label className="form__item--label">
            <b className="orange">*</b> Email
          </label>
          <input
            className="input"
            name="user_email"
            type="email"
            required
          ></input>
        </div>
        <div className="form__item">
          <label className="form__item--label">
            <b className="orange">*</b> Message
          </label>
          <textarea
            className="input"
            name="message"
            type="text"
            required
          ></textarea>
        </div>
        <button id="contact__submit" className="form__submit">
          Send it my way!
        </button>
      </form>
      <div id="modal__overlay--LOADING" className="modal__overlay--invisible ">
        <i>
          <FaSpinner className="fas fa-spinner" />
        </i>
      </div>
      <div id="modal__overlay--SUCCESS" className="modal__overlay--invisible">
        Thanks for the message! Looking forward to speaking to you soon.
      </div>
    </motion.div>
  );
}

export default ModalContact;
