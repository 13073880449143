import React from 'react'
import './ScrollFigure.css'

function ScrollFigure() {
  return (
    <a href="#projects" className="scroll">
      <div className="scroll__icon click"></div>
    </a>
  );
}

export default ScrollFigure