import React from "react";
import "./Modal.css";
import { motion } from "framer-motion";
import ModalAbout from "./ModalAbout";
import ModalContact from "./ModalContact";

function Modal({ setModal, modal, setShow }) {
  return (
    <>
      <motion.div
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        transition={{
          duration: 0.8,
        }}
        id="modal"
      >
        <ModalAbout />

        <ModalContact setModal={setModal} setShow={setShow} />
      </motion.div>
    </>
  );
}

export default Modal;
