import React from "react";
import "./Nav.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleHalfStroke } from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import navLogo from "../assets/TW-logo.png";

function Nav({ toggleTheme, setModal, setShow }) {
  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      transition={{
        duration: 1,
      }}
      className="nav"
    >
      <figure>
        <img className="nav__image" src={navLogo} alt="" />
      </figure>
      <ul className="nav__links">
        <li className="nav__link">
          <a
          href="/"
          onClick={() => alert(`Busy working on this new project, coming soon :)`)}
            className="nav__link--anchor"
          >
            Browse
          </a>
        </li>
        <li className="nav__link">
          <a href="#"
            onClick={() => {
              setModal(true);
              setShow(false);
            }}
            className="nav__link--anchor"
          >
            About
          </a>
        </li>
        <li className="nav__link">
          <a href="#projects" className="nav__link--anchor">
            Projects
          </a>
        </li>
        <li className="nav__link">
          <a href="#"
            onClick={() => {
              setModal(true);
              setShow(false);
            }}
            className="nav__link--anchor"
          >
            Contact
          </a>
        </li>
        <li className="nav__link" onClick={toggleTheme}>
          <a href="#" className="nav__link--anchor">
            <FontAwesomeIcon icon={faCircleHalfStroke} />
          </a>
        </li>
      </ul>
    </motion.div>
  );
}

export default Nav;
