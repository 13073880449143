import React from "react";
import "./Header.css";
import { motion } from "framer-motion";
import { useEffect } from "react";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";



const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

function Header({ setModal, setShow }) {
  useEffect(() => {
    let hack = document.getElementById("hacking");

    let iterations = 0;

    const interval = setInterval(() => {
      hack.innerText = hack.innerText
        .split("")
        .map((letter, index) => {
          if (index < iterations) {
            return hack.dataset.value[index];
          }

          return letters[Math.floor(Math.random() * 26)];
        })
        .join("");

      if (iterations >= hack.dataset.value.length) clearInterval(interval);

      iterations += 1 / 8;
    }, 30);
  }, []);

  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      transition={{
        duration: 1,
      }}
      className="header"
    >
      <div className="headerContent">
        <h1 className="title">
          Hey
          <br />
          <span className="orange">
            I'm{" "}
            <span data-value="Torben" id="hacking">
              ??????
            </span>
          </span>
        </h1>
        <p className="header__para">
          I'm a <span className="orange">Frontend Software Engineer</span> with
          a strong passion for building web applications with great user
          experiences. Here's a bit more{" "}
          <span
            href="#landingPage"
            className="orange header__aboutMe"
            onClick={() => {
              setModal(true);
              setShow(false);
            }}
          >
            about me.
          </span>
        </p>
        <div className="social__hoverEffects">
          <ul className="hover__link--list">
            <a
              href="https://github.com/TorbenWintebach"
              target="_blank"
              rel="noreferrer"
            >
              <li className="facebook">
                <span className="icon">
                  <GitHubIcon className="svgIcon" />
                </span>
                <span className="title">github</span>
              </li>
            </a>
            {/* <li className="twitter">
              <span className="icon">
                <TwitterIcon className="svgIcon" />
              </span>
              <span className="title">twitter</span>
            </li> */}
            {/* <li className="whatsapp">
              <span className="icon">
                <WhatsAppIcon className="svgIcon" />
              </span>
              <span className="title">whatsapp</span>
            </li> */}

            <a
              href="https://www.linkedin.com/in/torben-winterbach-8545a3265/"
              target="_blank"
              rel="noreferrer"
            >
              <li className="linkedin">
                <span className="icon">
                  <LinkedInIcon className="svgIcon" />
                </span>
                <span className="title">linkedin</span>
              </li>
            </a>
            <a
              href="https://www.instagram.com/torben._.wtb/"
              target="_blank"
              rel="noreferrer"
            >
              <li className="instagram">
                <span className="icon">
                  <InstagramIcon className="svgIcon" />
                </span>
                <span className="title">instagram</span>
              </li>
            </a>
          </ul>
        </div>

        {/* <div className="social__list">
          <a
            href="https://www.linkedin.com/in/torben-winterbach-04380b24a/"
            target="_blank"
            className="social__link click"
          >
            <FontAwesomeIcon icon={faLinkedinIn} />
          </a>
          <a
            href="https://github.com/TorbenWinterbach"
            target="_blank"
            className="social__link click"
          >
            <FontAwesomeIcon icon={faGithub} />
          </a>
          <a href="" className="social__link click">
            <FontAwesomeIcon icon={faEnvelope} />
          </a>
        </div> */}
      </div>
    </motion.div>
  );
}

export default Header;
