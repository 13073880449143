import { useState, createContext, } from "react";
import "./App.css";
import BackgroundImages from "./components/BackgroundImages";
import ContactMe from "./components/ContactMe";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Modal from "./components/Modal";
import Nav from "./components/Nav";
import Projects from "./components/Projects";
import ScrollFigure from "./components/ScrollFigure";



export const ThemeContext = createContext(null);

function App() {
  const [modal, setModal] = useState(false);
  const [show, setShow] = useState(true);
  const scaleFactor = 1 / 20;
  const [theme, setTheme] = useState("light");
  
  const toggleTheme = () => {
    setTheme((curr) => (curr === "light" ? "dark" : "light"));
    console.log("theme changed");
  

  document.addEventListener("mousemove", moveBackground);
  function moveBackground(e) {
    const shapes = document.querySelectorAll(".shape");
    const x = e.clientX * scaleFactor;
    const y = e.clientY * scaleFactor;

    for (let i = 0; i < shapes.length; ++i) {
      const isOdd = i % 2 !== 0;
      const boolInt = isOdd ? -1 : 1;
      shapes[i].style.transform = `translate(${x * boolInt}px, ${
        y * boolInt
      }px) rotate(${x * boolInt * 10}deg)`;
    }
  }

  }

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <div className="app" id={theme}>
      
        <section id="landingPage">
          {show && (
            <Nav
              toggleTheme={toggleTheme}
              setModal={setModal}
              setShow={setShow}
            />
          )}
          <BackgroundImages />
          <ContactMe
            modal={modal}
            show={show}
            setShow={setShow}
            setModal={setModal}
          />
          <ScrollFigure />
          {modal && <Modal setShow={setShow} setModal={setModal} />}

          {show && <Header setModal={setModal} setShow={setShow} />}
        </section>
        <section id="projects">
          <Projects />
        </section>
        <Footer setModal={setModal} setShow={setShow} />
      </div>
    </ThemeContext.Provider>
  );
}

export default App;
