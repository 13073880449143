import React from 'react'
import './ContactMe.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

function ContactMe({setModal, modal, setShow, show}) {
    

  return (
    <div className="contactMe">
      <a href="#">
        <button onClick={() => {
            setModal(!modal);
            setShow(!show);
        }}
        className="mail__btn click" >
          <FontAwesomeIcon icon={faEnvelope} />
        </button>
      </a>
    </div>
  );
}

export default ContactMe

