import React from "react";
import "./ModalLanguage.css";

function ModalLanguage({ image, name }) {
  return (
    <div className="modal__language">
        <img
          className="modal__language--img"
          src={image}
          alt=""
        />
        <span className="language__name">{name}</span>
    </div>
  );
}

export default ModalLanguage;
