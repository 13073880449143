import React from "react";
import "./Project.css";
import Project from "./Project";
import FineDineImage from "../assets/fine-dine-restaurant-cover-image.png";
import GPT3Image from "../assets/gpt3-image.png";
import HooBankImage from "../assets/hoo_bank_image.png";
import LibraryImage from "../assets/library-image.png";
import UltraverseImage from "../assets/Ultraverse-cover.png";

function Projects() {
  return (
    <div id="projects">
      <div className="container">
        <h1 className="section__title">
          Here are some of my <span className="orange">projects</span>
        </h1>
        <ul className="project__list">
          <Project
            image={UltraverseImage}
            title="Online NFT Store"
            subTitle="HTML, CSS, JavaScript, React"
            para="This is my most difficult project. All the information about the NFT's is pulled from an external API. All of the information is dynamic and automatic."
            link="https://virtual-internship-2766a.web.app/"
          />
          <Project
            image={LibraryImage}
            title="Online Library Store"
            subTitle="HTML, CSS, JavaScript, React"
            para="This was my first big project where I had to use an API to pull data about the books and then display it and make them purchasable"
            link="https://online-library-project-c82cd.web.app/"
          />
          <Project
            image="https://play-lh.googleusercontent.com/1-hPxafOxdYpYZEOKzNIkSP43HXCNftVJVttoo4ucl7rsMASXW3Xr6GlXURCubE1tA=w3840-h2160-rw"
            title="Search Engine Project (Google)"
            subTitle="HTML, CSS, JavaScript, React"
            para="I used the google custom search API to pull the search results and deploy them on my clone. My goal was to recreate an older version of google as precicely as I could, even focusing on the smallest details. "
            link="https://clone-aa2f3.web.app/"
          />
          
          <Project
            image="https://images.pexels.com/photos/5798000/pexels-photo-5798000.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            title="Hair Salon Project (Ilana's Hair and Beauty)"
            subTitle="HTML, CSS, JavaScript, React, Firebase"
            para="For this build I went to a local Hair Salon to offer a website, since they surprisingly didnt have one yet. This was my first solo Project and probably the biggest building block in my learning curve."
            link="https://ilana-s-hair-gallery.web.app/"
          />
          <Project
            image={FineDineImage}
            title="Modern Restaurant Project (Fine Dine)"
            subTitle="HTML, CSS, JavaScript, React, Firebase"
            para="My goal for this build was to create a very modern looking and eye pleasing restaurant website"
            link="https://fine-dine-restaurant.web.app/"
          />
          <Project
            image={GPT3Image}
            title="GPT-3 Open-AI Project"
            subTitle="HTML, CSS, JavaScript, React, Firebase"
            para="Chat GPT has been blowing up, so I decided to build a very techy looking AI themed website."
            link="https://gpt-3-38b03.web.app/"
          />
          <Project
            image={HooBankImage}
            title="HooBank modern design project"
            subTitle="HTML, CSS, JavaScript, React, Vite, Tailwind, Firebase"
            para="Hoo Bank claims to be one of the most modern website designs out there, so I made it a project to clone the website."
            link="https://hoobankappclone.netlify.app/"
          />
        </ul>
      </div>
    </div>
  );
}

export default Projects;
