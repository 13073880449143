import React from "react";
import "./BackgroundImages.css";
import semiCircle from "../assets/semi circle.svg";
import circle from "../assets/circle.svg";
import squiggly from "../assets/squiggly.svg";
import triangle from "../assets/triangle.svg";
import { motion } from "framer-motion";


function BackgroundImages() {
 

  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      transition={{
        duration: 1,
      }}
      className="backgroundImages"
    >
      <img src={semiCircle} alt="semi circle" className="shape shape--0" />
      <img src={circle} alt="cirlce" className="shape shape--1" />
      <img src={squiggly} alt="squiggly" className="shape shape--2" />
      <img src={circle} alt="circle" className="shape shape--3" />
      <img src={triangle} alt="squiggly" className="shape shape--4" />
      <img src={circle} alt="circle" className="shape shape--5" />
      <img src={squiggly} alt="squiggly" className="shape shape--6" />
      <img src={circle} alt="circle" className="shape shape--7" />
      <img src={semiCircle} alt="semi circle" className="shape shape--8" />
    </motion.div>
  );
}

export default BackgroundImages;
