import React from "react";
import "./Project.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";

function Project({ image, title, subTitle, para, link }) {
  return (
    <li className="project">
      <div className="project__wrapper">
        <img src={image} className="project__img" alt="" />
        <div className="project__wrapper--bg"></div>
        <div className="project__description">
          <h3 className="project__description--title">{title}</h3>
          <h4 className="project__description--sub-title">{subTitle}</h4>
          <p className="project__description--para">{para}</p>
          <div className="project__description--links">
            <a
              href="https://github.com/TorbenWinterbach"
              target="_blank"
              rel="noreferrer"
              className="project__description--link"
            >
            </a>
            <a
              href={link}
              target="_blank"
              rel="noreferrer"
              className="project__description--link"
            >
              <FontAwesomeIcon icon={faLink} />
            </a>
          </div>
        </div>
      </div>
    </li>
  );
}

export default Project;
